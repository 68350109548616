
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import UserResource from '@/resources/UserResource';
import Form from '@/components/User/Form.vue';
import useForm from '@/composables/use-form';

export default defineComponent({
    components: { Form },

    setup() {
        const router = useRouter();
        const user = {
            role: {},
        };
        const form = useForm(user, UserResource);
        const { isSaving, save  } = form;

        function submit() {
            save().then((response) => {
                router.go(-1);
            });
        }

        return {
            form,
            isSaving,
            submit,
        };
    },
});
